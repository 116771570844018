import { getBrandById, updateBrand, getBrandByDomain, getMyBrands, getMyBrandsVisit } from '@/services/brand'

export default {
  namespace: 'brand',
  state: {
    curBrand: null,
    myBrands: null,
    myBrandsVisit: null,
  },
  effects: {
    *getBrandById({ payload }, { call, put }) {
      const response = yield call(getBrandById, payload)
      yield put({
        type: 'save',
        payload: {
          curBrand: response.result,
        },
      })
      return response
    },
    *updateBrand({ payload }, { call, put }) {
      const response = yield call(updateBrand, payload)
      return response
    },
    *getBrandByDomain({ payload }, { call, put }) {
      const response = yield call(getBrandByDomain, payload)
      yield put({
        type: 'save',
        payload: {
          curBrand: response.result ? response.result[0] : null,
        },
      })
      return response
    },
    // 获取我的品牌
    *getMyBrands({ payload }, { call, put }) {
      const response = yield call(getMyBrands, payload)
      yield put({
        type: 'save',
        payload: {
          myBrands: response.result,
        },
      })
      return response
    },
    // 获取我的品牌
    *getMyBrandsVisit({ payload }, { call, put }) {
      const response = yield call(getMyBrandsVisit, payload)
      yield put({
        type: 'save',
        payload: {
          myBrandsVisit: response.result,
        },
      })
      return response
    },
  },
  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        ...payload,
      }
    },
  },
}
