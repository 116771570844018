import dva from 'dva';

import 'antd/dist/antd.css'
import './index.scss';

import models from './models';
// 1. Initialize
const app = dva();

// 2. Plugins
// app.use({});

// 3. Model
// app.model();
models.forEach((m) => app.model(m));

// 4. Router
app.router(require('./App').default);

// 5. Start
app.start('#root');
