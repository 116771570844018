import AV from 'leancloud-storage';
import * as qiniu from 'qiniu-js';

const Market = AV.Object.extend('Market');

export async function getList(params) {
  const queryMarket = new AV.Query('Market');
  if (params.status && params.status > 0) { queryMarket.equalTo('status', params.status); }
  queryMarket.descending('createdAt');

  return new Promise((resolve) => {
    queryMarket.find().then((res) => {
      resolve({
        result: JSON.parse(JSON.stringify(res)),
        code: 0,
        msg: 'success',
      });
    });
  });
}

export async function getById(params) {
  // queryMarket.equalTo('lastName', 'Smith');
  const queryMarket = new AV.Query('Market');
  return new Promise((resolve) => {
    queryMarket.get(params.id).then((res) => {
      resolve({
        result: JSON.parse(JSON.stringify(res)),
        code: 0,
        msg: 'success',
      });
    });
  });
}
