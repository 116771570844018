import { createAssets, getMyAssetsList } from '@/services/assets'

const initialState = {
  list: null,
}

const ProductModel = {
  namespace: 'assets',
  state: initialState,
  effects: {
    *createAssets({ payload }, { call, put }) {
      const response = yield call(createAssets, payload)
      return response
    },
    *getMyAssetsList({ payload }, { call, put }) {
      const response = yield call(getMyAssetsList, {})
      yield put({
        type: 'save',
        payload: {
          list: response.result,
        },
      })
      return response
    },
  },
  reducers: {
    save(state, action) {
      return { ...state, ...action.payload }
    },
  },
}
export default ProductModel
