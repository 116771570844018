const initialState = {
  brandData: null,
};

export default {
  namespace: 'home',
  state: {
    ...initialState,
  },
  effects: {
  },
  reducers: {
    update(state, { payload }) {
      return { ...state, ...payload };
    },
  },
};
