import AV from 'leancloud-storage'

export async function createAssets(params) {
  // 构建对象
  const MyAssets = AV.Object.extend('MyAssets')
  const myassets = new MyAssets(params)
  myassets.set('info', params.info)
  myassets.set('user', AV.User.current())

  return new Promise((resolve, reject) => {
    myassets.save().then(
      (res) => {
        // 成功保存之后，执行其他逻辑
        resolve({
          result: res,
          code: 0,
          msg: 'success',
        })
      },
      (error) => {
        // 异常处理
        reject({
          result: error,
          code: -1,
          msg: 'failed',
        })
      }
    )
  })
}
export const createAssetsLean = (params) =>
  new Promise((resolve, reject) => {
    const file = new AV.File('avatar.jpg', params.e)
    // file.set('status', 1)
    // file.set('user', AV.User.current())
    file.save().then(
      async (res) => {
        console.log(`文件保存完成。objectId：${res.id}`)
        resolve(res)
      },
      (error) => {
        // 保存失败，可能是文件无法被读取，或者上传过程中出现问题
        reject(error)
      }
    )
  })
export async function getMyAssetsList(params) {
  const queryMyAssets = new AV.Query('MyAssets')
  queryMyAssets.equalTo('user', AV.User.current())
  queryMyAssets.descending('updatedAt')
  queryMyAssets.limit(50)
  return new Promise((resolve) => {
    queryMyAssets.find().then((res) => {
      resolve({
        result: JSON.parse(JSON.stringify(res)),
        code: 0,
        msg: 'success',
      })
    })
  })
}

