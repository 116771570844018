
import home from './home';
import brand from './brand';
import category from './category';
import product from './product';
import market from './market'
import assets from './assets'

export default [
  home,
  brand,
  category,
  product,
  market,
  assets,
];
