import AV from 'leancloud-storage';

// 数组拆分
export const splitArray = (data = [], number = 3) => {
  const result = [];
  for (let i = 0, len = data.length; i < len; i += number) {
    result.push(data.slice(i, i + number));
  }
  return result;
};

// 税号拆分
export const splitTax = (taxNumber) => {
  const result = taxNumber.replace(/[^\dA-Z]/g, '').replace(/(.{5})/g, '$1 ').trim();
  return result;
};


// leancloud初始化
export const leanInit = () => {
  AV.init({
    appId: process.env.REACT_APP_LEAN_APPID,
    appKey: process.env.REACT_APP_LEAN_KEY,
    serverURLs: process.env.REACT_APP_LEAN_SERVER,
  });
};

export const canUseWebP = () => {
  // eslint-disable-next-line no-undef
  const elem = document.createElement('canvas');
  if (elem.getContext && elem.getContext('2d')) {
    // was able or not to get WebP representation
    return elem.toDataURL('image/webp').indexOf('data:image/webp') === 0;
  }
  // very old browser like IE 8, canvas not supported
  return false;
};

export const makeImgLink = ({ url, type }) => {
  let newUrl = url;
  if (canUseWebP() && type === 'webp') {
    newUrl = `${process.env.REACT_APP_QINIU_URL}/${url}?imageMogr2/format/webp/blur/1x0/quality/75|imageslim`;
  } else if (type) {
    newUrl = `${process.env.REACT_APP_QINIU_URL}/${url}?imageView2/1/w/800/h/800/format/jpg/q/75|imageslim`;
  } else {
    newUrl = `${process.env.REACT_APP_QINIU_URL}/${url}`;
  }
  return newUrl;
};
