/* eslint-disable import/prefer-default-export */
import AV from 'leancloud-storage'

const Brand = AV.Object.extend('Brand')

export async function getBrandById(params) {
  const queryBrand = new AV.Query('Brand')
  return new Promise((resolve) => {
    queryBrand.get(params.id).then((res) => {
      resolve({
        result: JSON.parse(JSON.stringify(res)),
        code: 0,
        msg: 'success',
      })
    })
  })
}

export async function getBrandByDomain(params) {
  const queryBrand = new AV.Query('Brand')
  queryBrand.equalTo('domain', params.id)
  return new Promise((resolve) => {
    queryBrand.find().then((res) => {
      resolve({
        result: JSON.parse(JSON.stringify(res)),
        code: 0,
        msg: 'success',
      })
    })
  })
}

export async function getMyBrands() {
  const queryMyBrands = new AV.Query('MyBrands')
  queryMyBrands.equalTo('user_id', AV.User.current())
  queryMyBrands.equalTo('type', 1)
  queryMyBrands.include('brand_id')
  queryMyBrands.include('userbrand_id')
  queryMyBrands.descending('createdAt')

  return new Promise((resolve) => {
    queryMyBrands
      .find()
      .then((res) => {
        resolve({
          result: JSON.parse(JSON.stringify(res)),
          code: 0,
          msg: 'success',
        })
      })
      .catch((err) => {
        console.log({ err })
      })
      .finally(() => {})
  })
}

export async function getMyBrandsAll(params) {
  const queryMyBrands = new AV.Query('MyBrands')
  queryMyBrands.equalTo('brand_id', params.brand_id)
  queryMyBrands.include('user_id')
  queryMyBrands.descending('createdAt')

  return new Promise((resolve) => {
    queryMyBrands
      .find()
      .then((res) => {
        resolve({
          result: JSON.parse(JSON.stringify(res)),
          code: 0,
          msg: 'success',
        })
      })
      .catch((err) => {
        console.log({ err })
      })
      .finally(() => {})
  })
}

export async function getMyBrandsVisit() {
  const queryMyBrands = new AV.Query('MyBrands')
  queryMyBrands.equalTo('user_id', AV.User.current())
  queryMyBrands.equalTo('type', 2)
  queryMyBrands.include('brand_id')
  queryMyBrands.descending('createdAt')

  return new Promise((resolve) => {
    queryMyBrands
      .find()
      .then((res) => {
        resolve({
          result: JSON.parse(JSON.stringify(res)),
          code: 0,
          msg: 'success',
        })
      })
      .catch((err) => {
        console.log({ err })
      })
      .finally(() => {})
  })
}

export async function updateBrand(params) {
  // 构建对象
  const brand = AV.Object.createWithoutData('Brand', params.id)
  const { values } = params
  Object.keys(values).map((obj) => {
    brand.set(`${obj}`, values[obj])
    return obj
  })

  return new Promise((resolve, reject) => {
    brand.save().then(
      (res) => {
        // 成功保存之后，执行其他逻辑
        resolve({
          result: res,
          code: 0,
          msg: 'success',
        })
      },
      (error) => {
        // 异常处理
        reject({
          result: error,
          code: -1,
          msg: 'failed',
        })
      }
    )
  })
}
