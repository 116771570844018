import {
  createCategory, getCategoryList, updateCategory,
} from '@/services/category';

const initialState = {
  curCategory: null,
  listLevel1: null,
  listLevel2: null,
};

const ProductModel = {
  namespace: 'category',
  state: initialState,
  effects: {
    * createCategory({ payload }, { call, put }) {
      const response = yield call(createCategory, payload);
      return response;
    },
    * updateCategory({ payload }, { call, put }) {
      const response = yield call(updateCategory, payload);
      return response;
    },
    * getCategoryList({ payload }, { call, put }) {
      const response = yield call(getCategoryList, payload);

      yield put({
        type: 'save',
        payload: {
          [`listLevel${payload.level}`]: response.result,
        },
      });
      return response;
    },
  },
  reducers: {
    save(state, action) {
      return { ...state, ...action.payload };
    },
  },
};
export default ProductModel;
