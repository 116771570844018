import {
  getList, getById,
} from '@/services/market';

const initialState = {
  curMarket: null,
  list: null,
  curPage: 1,
  skuList: [],
  imageList: [],
};

const MarketModel = {
  namespace: 'market',
  state: initialState,
  effects: {
    * getList({ payload }, { call, put }) {
      const response = yield call(getList, payload);
      yield put({
        type: 'save',
        payload: {
          list: response.result,
        },
      });
      return response;
    },

    * getById({ payload }, { call, put }) {
      const response = yield call(getById, payload);
      yield put({
        type: 'save',
        payload: {
          curMarket: response.result,
        },
      });
      return response;
    },
  },
  reducers: {
    save(state, action) {
      return { ...state, ...action.payload };
    },
  },
};
export default MarketModel;
