import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import { leanInit } from '@/utils';
// import NoMatch from '@/pages/NoMatch';
// import routers from './router';
import './App.scss'
import AnimatedSwitch from './AnimatedSwitch';

const Index = lazy(() => import('@/pages/Index'));
const About = lazy(() => import('@/pages/About'));
const Login = lazy(() => import('@/pages/Login'))
const Center = lazy(() => import('@/pages/Center'))
const Account = lazy(() => import('@/pages/Account'))
const Brand = lazy(() => import('@/pages/Brand'));
const BrandOfficial = lazy(() => import('@/pages/Brand/Official'));

function App() {
  leanInit();
  const loading = <div style={{ width: '100vw', height: '100vh', backgroundColor: 'rgba(0,0,0,0.1)' }} />;
  return (
    <Router>
      <Suspense fallback={loading}>
        {/* <Switch>
          {routers.map((item, index) => (
            <Route
              key={`${index + 1}`}
              exact
              path={item.path}
              component={item.component}
            />
          ))}
          <Route component={NoMatch} />
        </Switch> */}
        <Switch>
          <Route exact path="/">
            <Index />
          </Route>
          <Route path="/brand">
            <Brand />
          </Route>
          <Route path="/official/:id">
            <BrandOfficial />
          </Route>
          <Route path="/o/:id">
            <BrandOfficial />
          </Route>
          <Route path="/login">
            <Login />
          </Route>
          <Route path="/center">
            <Center />
          </Route>
          <Route path="/account">
            <Account />
          </Route>
        </Switch>
        {/* <AnimatedSwitch>
          <Route exact path="/">
            <Index />
          </Route>
          <Route path="/brand">
            <Brand />
          </Route>
          <Route path="/official/:id">
            <BrandOfficial />
          </Route>
        </AnimatedSwitch> */}
      </Suspense>
    </Router>
  )
}

export default App;
