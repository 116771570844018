import AV from 'leancloud-storage';

const Category = AV.Object.extend('Category');
const queryCategory = new AV.Query('Category');

export async function createCategory(params) {
  // 构建对象
  const category = new Category(params);
  category.set('name', params.name);
  if (params.category_id) {
    category.set(
      'category_id',
      AV.Object.createWithoutData('Category', params.category_id),
    );
  }
  return new Promise((resolve, reject) => {
    category.save().then(
      (res) => {
        // 成功保存之后，执行其他逻辑
        resolve({
          result: res,
          code: 0,
          msg: 'success',
        });
      },
      (error) => {
        // 异常处理
        reject({
          result: error,
          code: -1,
          msg: 'failed',
        });
      },
    );
  });
}
export async function updateCategory(params) {
  // 构建对象
  const category = AV.Object.createWithoutData('Category', params.id);
  const { values } = params;
  Object.keys(values).map((obj) => {
    category.set(`${obj}`, values[obj]);
    return obj;
  });

  return new Promise((resolve, reject) => {
    category.save().then(
      (res) => {
        // 成功保存之后，执行其他逻辑
        resolve({
          result: res,
          code: 0,
          msg: 'success',
        });
      },
      (error) => {
        // 异常处理
        reject({
          result: error,
          code: -1,
          msg: 'failed',
        });
      },
    );
  });
}

export async function getCategoryList(params) {
  queryCategory.equalTo('level', params.level);
  if (params.category_id) {
    queryCategory.equalTo('category_id', AV.Object.createWithoutData('Category', params.category_id));
  }

  // queryCategory.descending('updatedAt')

  return new Promise((resolve) => {
    queryCategory.find().then((res) => {
      resolve({
        result: JSON.parse(JSON.stringify(res)),
        code: 0,
        msg: 'success',
      });
    });
  });
}
