import {
  createSku, getSkuList, updateSku,
  createImage, getImageList, updateImage,
  createProduct, updateProduct, getList, getById,
} from '@/services/product';

const initialState = {
  curProduct: null,
  list: null,
  curPage: 1,
  skuList: [],
  imageList: [],
};

const ProductModel = {
  namespace: 'product',
  state: initialState,
  effects: {
    * createSku({ payload }, { call, put }) {
      const response = yield call(createSku, payload);
      return response;
    },
    * updateSku({ payload }, { call, put }) {
      const response = yield call(updateSku, payload);
      return response;
    },
    * getSkuList({ payload }, { call, put }) {
      const response = yield call(getSkuList, {
        product_id: payload.id,
      });
      yield put({
        type: 'save',
        payload: {
          skuList: response.result,
        },
      });
      return response;
    },


    * createImage({ payload }, { call, put }) {
      const response = yield call(createImage, payload);
      return response;
    },
    * getImageList({ payload }, { call, put }) {
      const response = yield call(getImageList, {
        product_id: payload.product_id,
      });
      yield put({
        type: 'save',
        payload: {
          [`imageList_${payload.id}`]: response.result,
        },
      });
      return response;
    },
    * updateImage({ payload }, { call, put }) {
      const response = yield call(updateImage, payload);
      return response;
    },


    * createProduct({ payload }, { call, put }) {
      const response = yield call(createProduct, payload);
      yield put({
        type: 'save',
        payload: {
          curProduct: response.result,
        },
      });
      return response;
    },
    * updateProduct({ payload }, { call, put }) {
      const response = yield call(updateProduct, payload);
      yield put({
        type: 'save',
        payload: {
          curProduct: response.result,
        },
      });
      return response;
    },


    * getList({ payload }, { call, put }) {
      const response = yield call(getList, payload);
      yield put({
        type: 'save',
        payload: {
          list: response.result,
        },
      });
      return response;
    },

    * getById({ payload }, { call, put }) {
      const response = yield call(getById, payload);
      yield put({
        type: 'save',
        payload: {
          curProduct: response.result,
        },
      });
      return response;
    },
  },
  reducers: {
    save(state, action) {
      return { ...state, ...action.payload };
    },

    // changeNotifyCount(
    //   state = {
    //     currentUser: {},
    //   },
    //   action,
    // ) {
    //   return {
    //     ...state,
    //     currentUser: {
    //       ...state.currentUser,
    //       notifyCount: action.payload.totalCount,
    //       unreadCount: action.payload.unreadCount,
    //     },
    //   };
    // },
  },
};
export default ProductModel;
